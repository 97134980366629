import { BuyNowPayLaterConfig } from "~features/checkout/checkout.types";
import { FeeType } from "~graphql/sdk";

export type OmitTypename<T> = Omit<T, "__typename">;

export const getCurrentPrice = (id, types, zone) =>
  zone?.find((zone) => {
    const currentType = types?.find((type) => type?.id === id);

    return (
      zone?.ticketTypeId === currentType?.id ||
      zone?.membershipTypeId === currentType?.id
    );
  });

export const calculateMultibuyDiscount = (promo, types, zone) => {
  const ticketPrice = (getCurrentPrice(promo?.getType?.id, types, zone)
    ?.price ??
    promo?.getType?.price ??
    0) as number;

  if (promo?.promotion?.price > 0) {
    const multiBuyItemPrice = Math.min(
      ticketPrice,
      promo.promotion.price as number
    );

    if (promo.promotion.price > ticketPrice) {
      return 0;
    }

    return (ticketPrice - multiBuyItemPrice) * promo.giftedQty;
  }

  return (promo?.giftedQty || 0) * ticketPrice;
};

type optionsConfig = {
  fee?: number | null;
  type?: FeeType | null;
  showHiddenFees?: boolean;
};

export const calculateTotal = (
  items,
  types,
  zones,
  options: optionsConfig = {}
): number => {
  const total: number = items.reduce((acc, item) => {
    let currentPrice =
      getCurrentPrice(
        item?.id,
        types,
        zones?.find((zone) => zone.name === item?.zoneId)?.ticketTypes ??
          zones?.find((zone) => zone.name === item?.zoneId)?.membershipTypes
      )?.price ??
      item?.price ??
      0;

    let ticketFee = 0;

    let stadiumLevyFee = 0;

    // Event ticket type fee
    if (options.showHiddenFees && item.bookingFee) {
      if (item.bookingFeeType === FeeType.Percentage) {
        ticketFee = currentPrice * (item.bookingFee / 100);
      } else {
        ticketFee = item.bookingFee;
      }
    }

    if (options.showHiddenFees && item.stadiumLevyFee) {
      stadiumLevyFee = item.stadiumLevyFee;
    }

    // Membership type fee
    if (options.showHiddenFees && item.ticketFee) {
      if (item.ticketFeeType === FeeType.Percentage) {
        ticketFee = currentPrice * (item.ticketFee / 100);
      } else {
        ticketFee = item.ticketFee;
      }
    }

    currentPrice += ticketFee + stadiumLevyFee;

    return acc + currentPrice * item.quantity;
  }, 0);

  let bookingFee = 0;

  if (options.showHiddenFees && options.fee) {
    if (options.type === FeeType.Percentage) {
      bookingFee = total * (options.fee / 100);
    } else {
      bookingFee = options.fee;
    }
  }

  return total > 0 ? total + bookingFee : total;
};

export const calculateBNPLPrice = (
  total: number,
  config: BuyNowPayLaterConfig,
  options: optionsConfig = {}
): number => {
  let fee = 0;

  if (options.showHiddenFees && options.fee) {
    if (options.type === FeeType.Percentage) {
      fee = total * (options.fee / 100);
    } else {
      fee = options.fee;
    }
  }

  return (total + fee) / config.terms;
};

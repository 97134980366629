import { apiUrl } from "~config";
import * as Sentry from "@sentry/nextjs";

export const downloadTicket = (
  orgId: string,
  ticketIds: string | string[],
  eventTicketsForMembership?: boolean
) => {
  const anchor = document.createElement("a");

  const eventTicketsForMembershipQuery = eventTicketsForMembership
    ? `?eventTicketsForMembership=${eventTicketsForMembership}`
    : "";

  anchor.href = `${apiUrl}/ticket/${orgId}/${
    Array.isArray(ticketIds) ? ticketIds.join() : ticketIds
  }${eventTicketsForMembershipQuery}`;

  anchor.target = "_blank";
  anchor.download = "your_ticket.pdf";

  return anchor.click();
};

export const downloadOrderTickets = (orgId: string, orderId: string) => {
  const anchor = document.createElement("a");

  anchor.href = `${apiUrl}/ticket/order/${orgId}/${orderId}`;

  anchor.target = "_blank";
  anchor.download = "your_ticket.pdf";

  return anchor.click();
};

// TEMP: Just wanna know how often this happens
export const captureDownloadTicketFailure = (
  dataSource: string,
  operation?: string,
  tickets?: any
) => {
  Sentry.captureException(new Error("No active tickets to download"), {
    tags: {
      operation: operation ?? "unknown",
      dataSource: dataSource,
    },
    extra: {
      tickets: JSON.stringify(tickets),
    },
  });
};

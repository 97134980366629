import { useContext } from "react";
import { BaseContext } from "~context";
import { BNPLIntegration } from "~features/checkout/checkout.types";
import { BNPL_CONFIG } from "~features/checkout/constants";
import { calculateBNPLPrice, OrganizationFeatures } from "~lib";

export const useBNPLPriceCalculation = (
  totalPrice: number,
  bnplIntegration: Partial<BNPLIntegration>
) => {
  const { organization } = useContext(BaseContext);
  const config = BNPL_CONFIG[bnplIntegration?.type];

  const showHiddenFees = organization.features?.includes(
    OrganizationFeatures.ShowHiddenFees
  );

  const price = calculateBNPLPrice(totalPrice, config, {
    showHiddenFees,
    fee: bnplIntegration?.transactionFee,
    type: bnplIntegration?.transactionFeeType,
  });

  return {
    bnplPrice: price,
    showHiddenFees,
    bnplIntegration,
  };
};

import { useContext } from "react";
import { BaseContext } from "~context";

type OrganizationFeatures =
  | "showHiddenFees"
  | "ticketTransfers"
  | "digitalEvents"
  | "vaccinePassport"
  | "tags"
  | "points"
  | "fiji"
  | "pinPaymentGateway"
  | "eventDetailsV2" // No longer in use.
  | "disablePageLoadTracing"
  | "ticketResale"
  | "resaleTicketPriceCollection";

export const useOrganization = () => {
  const ctx = useContext(BaseContext);

  if (ctx === undefined) {
    throw new Error("Context must be used within a provider!");
  }

  const hasFeature = (feature: OrganizationFeatures): boolean =>
    ctx?.organization?.features?.includes(feature);

  return { organization: ctx?.organization, hasFeature };
};

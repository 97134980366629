import merge from "lodash/merge";
import { DefaultSeoProps } from "next-seo";
import { CurrentOrganizationQuery } from "~graphql/sdk";
const siteTitle = "Flicket";

interface SeoConfigProps {
  organization: CurrentOrganizationQuery["currentOrganization"];
  url: string;
  options?: DefaultSeoProps;
}

export const getDefaultSeoConfig = ({
  organization,
  url,
  options = {},
}: SeoConfigProps): DefaultSeoProps => {
  // console.log("organization", JSON.stringify(organization, null, 2));
  const defaultOptions = {
    title: `${organization?.name} | ${siteTitle}`,
    defaultTitle: `${organization?.name} | ${siteTitle}`,
    canonical: url,
    openGraph: {
      type: "website",
      locale: "en_IE",
      url,
      site_name: `${organization?.name} | ${siteTitle}`,
    },
    twitter: {
      handle: "@flicket",
      cardType: "summary_large_image",
    },
    titleTemplate: `%s | ${`${organization?.name} - ${siteTitle}`}`,
  };

  return merge(defaultOptions, options);
};

import * as Sentry from "@sentry/nextjs";
import axios from "axios";
import { NextPage, NextPageContext } from "next";
import { DefaultSeo } from "next-seo";
import { AppProps } from "next/app";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { mutate, SWRConfig } from "swr";
import { setLocale } from "yup";

import "react-toastify/dist/ReactToastify.css";
import "swiper/css/swiper.css";
import { Providers, Seo as SeoComp, SeoProps } from "~components";
import { apiUrl, getDefaultSeoConfig } from "~config";
import {
  useFacebookPixelScripts,
  useThirdpartyScripts,
} from "~features/analytics/useThirdpartyScripts";
import { CurrentOrganizationQuery, EventQuery } from "~graphql/sdk";
import { i18n, pageviewFB, protocol, sdk } from "~lib";
import { getImage, handleNotFoundRedirect, handlePromise } from "~lib/helpers";
import { store } from "~lib/store";
import CSSreset from "~styles/CSSreset";

import { withAuth } from "~features/auth/withAuth";
import { _getEvent } from "~graphql/fetchers";
import { EmptyObject } from "~lib/type-utils";
import "../../public/static/fonts/stylesheet.css";
import { userAgent } from "~lib/helpers/constants";
import {
  generateOgDescription,
  generateSEODescription,
} from "~lib/helpers/formatMetaDescriptions";
import FrontendTracer from "~telemetry/FrontendTracer";
import { atom, PrimitiveAtom, useAtom } from "jotai";
import { useHydrateAtoms } from "jotai/utils";

axios.defaults.baseURL = apiUrl;
axios.defaults.withCredentials = true;

setLocale({
  mixed: {
    required: "This field is required",
  },
  string: {
    email: "Invalid email address",
  },
});

toast.configure({
  position: "bottom-right",
  autoClose: 4000,
  closeButton: false,
  hideProgressBar: true,
});

const authRestrictedRoutes = [
  /^tickets$/i,
  /^orders$/i,
  /^account/i,
  /^members-area/i,
];

if (typeof window !== "undefined") {
  void FrontendTracer();
}

export const pixelIdAtom = atom<string | undefined>(undefined) as PrimitiveAtom<
  string | undefined
>;

function useQueue(organizationFeatures: string[]) {
  const isQueueEnabled = organizationFeatures.includes("queue");

  const [inQueue, setInQueue] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined" || !isQueueEnabled) {
      return;
    }

    const client = document.createElement("script");
    client.src = "//static.queue-it.net/script/queueclient.min.js";
    document.head.appendChild(client);

    const config = document.createElement("script");
    config.src = "//static.queue-it.net/script/queueconfigloader.min.js";
    config.setAttribute("data-queueit-spa", "true");
    config.setAttribute("data-queueit-c", "flicketnz");
    document.head.appendChild(config);

    const onQueuePassed = () => setInQueue(false);

    // Capture event fired from Queue-it once user has successfully been through the queue
    window.addEventListener("queuePassed", onQueuePassed);

    return () => {
      window.removeEventListener("queuePassed", onQueuePassed);
    };
  }, []);

  return inQueue;
}

interface AppPageProps extends AppProps {
  props: {
    organization: CurrentOrganizationQuery["currentOrganization"];
    event: EventQuery["event"];
    // release: Release;
    url: string;
  };
}

const App = ({ Component, pageProps, props, router }: AppPageProps) => {
  const redirectToQueue = useQueue(props.organization?.features ?? []);

  useThirdpartyScripts({
    gtmId: props.organization?.marketing?.gtmId,
  });

  useHydrateAtoms([[pixelIdAtom, props.organization?.marketing?.pixelId]]);

  const [pixelId] = useAtom(pixelIdAtom);

  useFacebookPixelScripts(props.organization?.marketing?.pixelId);

  useEffect(() => {
    const handleRouteChange = () => {
      pageviewFB(pixelId);
    };

    if (pixelId) {
      router.events.on("routeChangeComplete", handleRouteChange);
      return () => {
        router.events.off("routeChangeComplete", handleRouteChange);
      };
    }
  }, [router.events, pixelId]);

  useEffect(() => {
    i18n.timezone = props.organization?.timezone;
  }, [props.organization?.timezone]);

  useEffect(() => {
    if (props.organization?.branding?.favicon) {
      document
        .getElementById("custom-favicon")
        .setAttribute("href", getImage(props.organization?.branding?.favicon));
    }
  }, [props.organization?.branding?.favicon]);

  let eventSeo: SeoProps | EmptyObject = {};

  if (props.event) {
    // Put event and release into SWR cache so that we can server render as much of the page as we can
    void mutate(["event", props.organization.id, props.event.id], props.event);

    // void mutate(
    //   [
    //     `release-${router.query.release as string}`,
    //     props.organization.id,
    //     props.event.id,
    //     router.query.release as string,
    //     router.query.slug as string,
    //   ],
    //   props.release
    // );

    const ogDescription: string = generateOgDescription(props.event);
    const seoDescription: string = generateSEODescription(props.event);

    eventSeo = {
      title: props.event.title,
      description: seoDescription,
      options: {
        openGraph: {
          url: props.url,
          title: props.event.title,
          description: ogDescription,
          images: [
            {
              url: getImage(props.event.thumbnail, ""),
              alt: `${props.event.title} tickets`,
            },
          ],
        },
      },
    };
  }

  const authRestricted = authRestrictedRoutes.some((route) =>
    route.test(router.pathname.slice(1))
  );

  const Page = authRestricted
    ? withAuth(Component, { redirect: true })
    : Component;

  return (
    <Providers {...props}>
      <SWRConfig
        value={{
          dedupingInterval: 5000,
          revalidateOnFocus: false,
          shouldRetryOnError: true,
          fetcher: async (url: string, params) =>
            axios(url, { params }).then((res: any): any => res?.data),
        }}
      >
        <CSSreset />
        <DefaultSeo
          {...getDefaultSeoConfig({
            organization: props.organization,
            url: props.url,
          })}
        />
        {props.event && <SeoComp {...(eventSeo as SeoProps)} />}

        {!redirectToQueue && (
          <Page {...pageProps} {...props} key={router.route} />
        )}
      </SWRConfig>
    </Providers>
  );
};

/**
  DISCLAIMER:

  By adding getInitialProps to the _app component we opt out Automatic Static Optimization.

  At the time of writing there is no way of implementing a specific method (like getServerSide or getStatic props)
  however those will result in the same behavior (not having the choice page by page).

  Reason we're opting out of SSG is that this specific project will always be gated behind an organization.
  We'll need to fetch this organization first before we can display anything.

  Authentication is never a reason to go this route (just go client-side).
  In this specific case it certainly isn't as the website isn't auth-gated

  So before using this, think on how the application should work!

  @NOTE USE WITH CAUTION
**/

export const getCurrentOrganization = async (host: string) =>
  handlePromise(async () =>
    sdk({
      host,
      key: "user-agent",
      value: userAgent,
    }).currentOrganization()
  );

App.getInitialProps = async ({
  Component,
  ctx,
}: {
  Component: NextPage;
  ctx: NextPageContext;
}) => {
  const { req } = ctx;
  const query = ctx.query as { [key: string]: string };

  const hostHeader = req?.headers
    ? `https://${req.headers["x-forwarded-host"] || req.headers.host}`
    : "";

  const shouldLoadOrganization =
    (!store.organization || store.host !== hostHeader) &&
    ctx.pathname !== "/not-found";

  const isServer = typeof window === "undefined";

  if (shouldLoadOrganization) {
    const { data, error } = await getCurrentOrganization(hostHeader);

    if (error) {
      Sentry.captureException(error, {
        tags: {
          ssr: isServer,
        },
      });

      // needed only when running Sentry in a serverless environment
      await Sentry.flush(2000);
    }

    /** redirect if the organization was not found  */
    if (!data || !data.currentOrganization || error) {
      return handleNotFoundRedirect(ctx);
    }

    store.organization = data.currentOrganization;
    store.host = hostHeader;
  }

  if (isServer && query.eventId && store.event?.id !== query.eventId) {
    const eventPromise = handlePromise(async () =>
      _getEvent({
        orgId: store.organization.id,
        eventId: query.eventId,
        sdkOptions: {
          isServer: true,
        },
      })
    );

    // const releasePromise = handlePromise(async () =>
    //   _getRelease({
    //     orgId: store.organization.id,
    //     eventId: query.eventId,
    //     releaseId: query.release,
    //     slug: query.slug,
    //     sdkOptions: {
    //       isServer: true,
    //     },
    //   })
    // );

    const [
      { data: event, error: eventError },
      // { data: release, error: releaseError },
    ] = await Promise.all([eventPromise]);

    if (event && !eventError) {
      store.event = event;
    }

    // if (release && !releaseError) {
    //   store.release = release;
    // }

    // if (eventError || releaseError) {
    //   Sentry.captureException(eventError || releaseError, {
    //     tags: {
    //       ssr: isServer,
    //     },
    //   });
    //   await Sentry.flush(2000);
    // }
  } else if (!query.eventId) {
    store.event = null;
    // store.release = null;
  }

  const currentUrl = `${protocol}://${
    req?.headers?.host ?? window?.location?.host
  }${ctx.asPath}`;
  if (!req?.headers?.host && !window?.location?.host) {
    Sentry.captureException({
      msg:
        "currentUrl with req?.headers?.referer & window?.location?.href failed",
      window,
      req,
      currentUrl,
    });
  }

  const props = {
    organization: store.organization,
    event: store.event,
    // release: store.release,
    url: currentUrl,
  };

  Object.assign(ctx, {
    organization: store.organization,
    event: store.event,
    // release: store.release,
  });

  if (Component.getInitialProps) {
    const cmpProps = await Component.getInitialProps(ctx);
    Object.assign(props, cmpProps);
  }

  return { props };
};

export default App;
